<template>
  <div id="mapRoot">
    <div id="container"></div>
    <div id="navIcon"  @click="handleMapNav">
      <img
       
        src="../../assets/img/nav.png"
        alt="导航"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  components: {},

  data() {
    return {  };
  },
  created() {
    document.title = "导航";
  },
  mounted() {

  const opts = {
    width: 360, // 信息窗口宽度
    height: 130, // 信息窗口高度
    title: "", // 信息窗口标题
  };
    // GL版命名空间为BMapGL
    // 按住鼠标右键，修改倾斜角和角度
    // @ts-ignore
    const { F_Longitude, F_Latitude, F_FullName, F_Address } =
      this.$route.query;
    // eslint-disable-next-line no-undef
    const map = new BMapGL.Map("container"); // 创建Map实例
    console.log("map",map,this.$route.query)
    // 经度 维度
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const point = new BMapGL.Point(Number(F_Longitude), Number(F_Latitude));
    map.centerAndZoom(point, 16); // 初始化地图,设置中心点坐标和地图级别
    map.enableScrollWheelZoom(); //开启鼠标滚轮缩放
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const marker = new BMapGL.Marker(point); // 创建标注
    map.addOverlay(marker);

    const html = `
    <div class="cont">
        <div class="lr">
        <div class="tit ttt">${F_FullName}</div>
            <div class="ff">
            <br/>
                <span class="add">地址 </span>:
                <span>${F_Address}</span>
            </div>
        </div>
    </div>
`;
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const infoWindow = new BMapGL.InfoWindow(html, opts); // 创建信息窗口对象
    map.openInfoWindow(infoWindow, map.getCenter()); // 打开信息窗口
    infoWindow.disableCloseOnClick();
  },
  methods: {
    handleMapNav() {
      const link = this.getMapScheme(
        {
          longitude: this.$route.query.F_Longitude,
          latitude: this.$route.query.F_Latitude,
          name: this.$route.query.F_FullName,
        }
      );
      console.log("link",link);
      window.location.href = link;
    },
    getMapScheme(to) {
      const isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      if (isMobile) {
        return `https://m.amap.com/share/index/lnglat=${to.longitude},${to.latitude}&name=${to.name}&src=uriapi&innersrc=uriapi`;
      }
      return `https://ditu.amap.com/regeo?lng=${to.longitude}&lat=${to.latitude}&name=${to.name}&src=uriapi&innersrc=uriapi`;
    },
  },
};
</script>

<style lang="less" scoped>
body,
#mapRoot {
  //   min-width: 1280px;
  position: "relative";
  z-index: 99;
  width: 100vw;
  height: 100vh;
  @font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  user-select: none;
  box-sizing: border-box;
  #container {
    width:100%;
    height:100%;
  }
  #navIcon {
    position: absolute;
    right:20px;
    bottom: 20px;
    width:30px;
    height:30px;
    z-index: 21474836479;
    img {
        width: "25px";
        z-index: 21474836479;
    }
  }
}
</style>
